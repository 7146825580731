<template>
          <div class="column is-one-quarter">
          <div class="container">
            <b-menu activable>
              <b-menu-list label="Menu">
                                <b-menu-item
                  @click="$router.push({ name: 'ExploratorStartSearch' })"
                  label="WYSZUKIWARKA USŁUG"
                ></b-menu-item>
                <b-menu-item @click="$router.push({ name: 'Cars' })" label="MÓJ WIRTUALNY GARAŻ"></b-menu-item>
                <b-menu-item
                  @click="$router.push({ name: 'Orders' })"
                  label="MOJE ZAMÓWIENIA"
                ></b-menu-item>
                <b-menu-item @click="$router.push({ name: 'Insurences' })" label="UBEZPIECZENIA"></b-menu-item>
                <b-menu-item @click="$router.push({ name: 'TechnicalDiagnostic' })" label="PRZEGLĄDY TECHNICZNE"></b-menu-item>
               <!--  <b-menu-item label="SPRZEDAJ SAMOCHÓD"></b-menu-item> -->
                
                <b-menu-item @click="$router.push({ name: 'Stats' })" label="STATYSTYKI" disabled></b-menu-item>
                <b-menu-item label="WYCEŃ WARTOŚĆ SAMOCHODU"></b-menu-item>
                <b-menu-item
                  label="DODAJ KOLEJNY SAMOCHÓD"
                  icon="plus"
                  tag="router-link"
                  :to="{ name: 'NewCar' }"
                ></b-menu-item>
                <b-menu-item @click="$router.push({ name: 'Profile' })" label="MOJE KONTO"></b-menu-item>
                <b-menu-item @click="logout" label="WYLOGUJ"></b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
        </div>
</template>

<script>
export default {
    name: 'UserNav',
    methods: {
      logout() {
        this.$store.dispatch("auth/logout");
        localStorage.clear();
        this.$router.push({ name: "Home" });
      },
    }
}
</script>

<style lang="scss" scoped>

</style>