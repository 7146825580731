<template>
  <div>
  <!--   <b-loading v-model="isLoading"> </b-loading> -->
    <client-nav></client-nav>
    <div class="section container">
      <div class="columns">
          <user-nav></user-nav>
        <div class="column">
              <h1 class="title is-2 has-text-primary has-text-centered">
              Przeglądy techniczne
            </h1>
            <div v-if="hasCars">
              <div class="is-flex is-flex-wrap-wrap">
                <div
                  class="m-1"
                  v-for="(car, index) in cars"
                  :key="`car-${index}`"
                >
                  <b-button
                    :type="carSelectionArray[index] ? 'is-primary' : ''"
                    @click="selectCar(index, car.id)"
                    rounded
                  >
                    {{ index + 1 }}.
                    {{ car.car_details.serie.model.brand.name }}
                    {{ car.car_details.serie.model.name }}
                  </b-button>
                </div>
              </div>
            </div>
            <hr />
            <div class="cols level is-flex-direction-row-reverse">
                <div class="level-right">
                    <b-button
                    @click="addTechnicalDiagnoseModal = true"
                     class="is-primary" rounded>Dodaj badanie techniczne</b-button>
                </div>
            </div>
            <div class="technical-diagnoses-list" v-if="technical_diagnoses && technical_diagnoses.length > 0">
                <!--TABELA-->
                <b-table :data="technical_diagnoses" >
            <b-table-column field="diagnose_company" width="10" label="Firma diagnostyczna"  v-slot="props">
                {{ props.row.diagnose_company }}
            </b-table-column>

            <b-table-column field="technical_diagnose_start"  width="20" label="Data przeglądu"  v-slot="props">
                {{ props.row.technical_diagnose_start }}
            </b-table-column>

            <b-table-column field="technical_diagnose_expire"  width="50" label="Wygaśnięcie przeglądu"  v-slot="props">
                {{ props.row.technical_diagnose_expire }}
            </b-table-column>

            <b-table-column field="notification" width="10" label="Powiadomienie"  v-slot="props">
                {{ props.row.notification ? 'TAK' : 'NIE' }}
            </b-table-column>

            <b-table-column field="actions" label="Akcje" width="20" v-slot="props" >
                <div class="columns">
                    <div class="column">
                        <b-button @click="show(props.row)" class="is-primary" size="is-small">Podgląd</b-button>
                    </div>
                    <div class="column">
                        <b-button @click="edit(props.row)" class="" size="is-small">Edycja</b-button>
                    </div>
                    <div class="column">
                        <b-button @click="remove(props.row)" class="is-dark" size="is-small">Usuń</b-button>
                    </div>
                </div>
                
                
            </b-table-column>

                </b-table>
            </div>
            <div v-else>Brak przeglądów technicznych dla tego pojazdu</div>
            <!--B-MODAL DODAJ-->
            <b-modal

                :can-cancel="['escape', 'x', 'outside']"
                v-model="addTechnicalDiagnoseModal"
            >
                <section>
                    <div class="modal-card"  style="width: auto"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Dodaj nowe badanie techniczne samochodu</div>
                    </header>                    
                    <div class="modal-card-body has-background-white">
                        <div class="">
                            <form class="new-technical-diagnoses-form ">
                                <b-field label="Firma diagnostyczna" horizontal>
                                    <b-input v-model="newTechnicalDiagnose.diagnose_company"></b-input>
                                </b-field>
                                <b-field label="Data badania" horizontal>
                                   <b-datepicker
                                        v-model="newTechnicalDiagnose.technical_diagnose_start"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Wygaśnięcie przeglądu" horizontal>
                               <b-datepicker
                                        v-model="newTechnicalDiagnose.technical_diagnose_expire"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Cena badania" horizontal>
                                    <b-input type="number" v-model="newTechnicalDiagnose.price"></b-input>
                                </b-field>
                                <b-field label="Badany samochód" horizontal>
                                    <b-select class="mt-2" expanded placeholder="Wybierz pojazd" v-model="newTechnicalDiagnose.my_car_id">
                                        <option v-for="(option, index) in cars"                
                                                    :value="option.id"
                                                    :key="option.id">
                                                   {{ index + 1 }} {{ option.car_details.serie.model.brand.name  }} {{ option.car_details.serie.model.name }}                
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field label="Notatka" horizontal>
                                    <b-input maxlength="200" type="textarea" v-model="newTechnicalDiagnose.note"></b-input>
                                </b-field>
                                <b-field label="Powiadomienie o zbliżającym się terminie?" horizontal>
                                    <b-switch class="mt-4" v-model="newTechnicalDiagnose.notification"></b-switch>
                                </b-field>                 
                                <div class="tile is-12">
                                    <b-button class="is-primary" type="submit" @click="add" expanded>Zapisz</b-button>
                                </div>                                                                                                                                                                                                               
                                
                            </form>
                            <div class="message mt-6" v-show="result">
                                <div class="success" v-if="!error">
                                    <b-message title="Operacja zakończona powodzeniem" type="is-primary" aria-close-label="Close message">
                                        Badanie techniczne zostało dodane
                                    </b-message>
                                </div>
                                <div class="error" v-else>
                                    <b-message title="Operacja zakończona niepowodzeniem" type="is-danger" aria-close-label="Close message">
                                        Nie udało się dodać badania. {{ errorContent }}
                                    </b-message>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer class="modal-card-foot"></footer>
                </section>
            </b-modal>
            <!--B-MODAL EDYTUJ-->
            <b-modal v-model="editTechnicalDiagnoseModal">
                <section>
                    <div class="modal-card"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Edytuj badanie techniczne</div>
                    </header>                    
                    <div class="modal-card-body">
                        <form class="new-technical-diagnose-form ">
                                <b-field label="Firma diagnostyczna" horizontal>
                                    <b-input v-model="editTechnicalDiagnose.diagnose_company"></b-input>
                                </b-field>
                                <b-field label="Data badania" horizontal>
                                   <b-datepicker
                                        v-model="editTechnicalDiagnose.technical_diagnose_start"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Wygaśnięcie badania" horizontal>
                               <b-datepicker
                                        v-model="editTechnicalDiagnose.technical_diagnose_expire"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Cena badania" horizontal>
                                    <b-input type="number" v-model="editTechnicalDiagnose.price"></b-input>
                                </b-field>
                                <b-field label="Badany samochód" horizontal>
                                    <b-select disabled class="mt-2" expanded placeholder="Wybierz pojazd" v-model="editTechnicalDiagnose.my_car_id">
                                        <option v-for="(option, index) in cars"                
                                                    :value="option.id"
                                                    :key="option.id">
                                                   {{ index + 1 }} {{ option.car_details.serie.model.brand.name  }} {{ option.car_details.serie.model.name }}                
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field label="Notatka" horizontal>
                                    <b-input maxlength="200" type="textarea" v-model="newTechnicalDiagnose.note"></b-input>
                                </b-field>
                                <b-field label="Powiadomienie o zbliżającym się terminie?" horizontal>
                                    <b-switch class="mt-4" v-model="newTechnicalDiagnose.notification"></b-switch>
                                </b-field>  
                        </form>
                    </div>
                    <footer class="modal-card-foot">
                        <b-button @click="saveEdit" class="is-primary">Zapisz</b-button>
                        <b-button @click="editTechnicalDiagnoseModal=false" class="is-dark">Anuluj</b-button>
                    </footer>
                </section>
            </b-modal>
            <!--B-MODAL SZCZEGÓŁY-->
            <b-modal v-model="detailsTechnicalDiagnoseModal">
                <section>
                    <div class="modal-card"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Badanie techniczne - szczegóły</div>
                    </header>                    
                    <div class="modal-card-body">
                        <div class="columns">
                            <div class="column">Firma diagnostyczna</div>
                            <div class="column">{{detailsTechnicalDiagnose.diagnose_company}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Data badania</div>
                            <div class="column">{{detailsTechnicalDiagnose.technical_diagnose_start}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Data wygaśnięcia badania</div>
                            <div class="column">{{detailsTechnicalDiagnose.technical_diagnose_expire}}</div>
                        </div>

                        <div class="columns">
                            <div class="column">Notatka</div>
                            <div class="column">{{detailsTechnicalDiagnose.note}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Powiadomienia</div>
                            <div class="column">{{detailsTechnicalDiagnose.notification ? 'TAK' : 'NIE'}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Cena</div>
                            <div class="column">{{detailsTechnicalDiagnose.price}} zł</div>
                        </div>
                    </div>
                    <footer class="modal-card-foot"></footer>
                </section>
            </b-modal>
                        <!--B-MODAL USUŃ-->
            <b-modal v-model="deleteTechnicalDiagnoseModal">
                <section>
                    <div class="modal-card"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Badanie techniczne - Czy chcesz je usunąć?</div>
                    </header>                    
                    <div class="modal-card-body">
                        <div class="columns">
                            <div class="column">Badanie techniczne -</div>
                            <div class="column">{{technicalDiagnoseToDelete && technicalDiagnoseToDelete.diagnose_company}}</div>
                        </div>
                    </div>
                    <footer class="modal-card-foot">
                        <b-button @click="confirmAndDelete" class="is-dark">Usuń</b-button>
                        <b-button @click="deleteTechnicalDiagnoseModal = false" class="is-primary">Anuluj</b-button>
                    </footer>
                </section>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ClientNav from "@/components/ClientNav";
import UserNav from '../../components/common/UserNav.vue';

export default {
    name: 'TechnicalDiagnose',
  components: {
    "client-nav": ClientNav,
    "user-nav": UserNav,

  },

  data() {
      return {
          newTechnicalDiagnose: {
              technical_diagnose_start: null,
              technical_diagnose_expire: null,
              diagnose_company: null,
              note: null,
              price: null,
              notification: false,
              my_car_id: null,
          },
          editTechnicalDiagnose: {},
          detailsTechnicalDiagnose: {},

          result: false,
          error: false,
          errorContent: 'aaa',

          addTechnicalDiagnoseModal: false,
          detailsTechnicalDiagnoseModal: false,
          editTechnicalDiagnoseModal: false,
          deleteTechnicalDiagnoseModal: false,
          technicalDiagnoseToDelete: null,

          carSelectionArray: [],
      }
  },

  created() {
  },

  methods: {
    add: function(){
        this.$store.dispatch("technicalDiagnose/add", {
            ...this.newTechnicalDiagnose,
              technical_diagnose_start: this.onlyDate(this.newTechnicalDiagnose.technical_diagnose_start),
              technical_diagnose_expire: this.onlyDate(this.newTechnicalDiagnose.technical_diagnose_expire),            
            }).then((res) => {
                res
           // console.log(res)
        }).catch((err) => {
            this.error = true
            this.errorContent = err
        }).finally(() => this.result = true)
    },
    onlyDate: function(dt){
        return dt.toISOString().split('T')[0]
    },
    edit: function(row){
        this.editTechnicalDiagnose = {...row, technical_diagnose_start: new Date(row.technical_diagnose_start), technical_diagnose_expire: new Date(row.technical_diagnose_expire)}
        this.editTechnicalDiagnoseModal = true
    },
    saveEdit: function(){
        this.$store.dispatch("technicalDiagnose/edit", {
            ...this.editTechnicalDiagnose,
              technical_diagnose_start: this.onlyDate(this.editTechnicalDiagnose.technical_diagnose_start),
              technical_diagnose_expire: this.onlyDate(this.editTechnicalDiagnose.technical_diagnose_expire),            
            }).then((res) => {
            if(res == true) this.editTechnicalDiagnoseModal = false
        }).catch((err) => {
            this.error = true
            this.errorContent = err
        }).finally(() => {
            this.result = true
            this.editTechnicalDiagnoseModal = false
        })
    },
    remove: function(row){
        this.deleteTechnicalDiagnoseModal = true
        this.technicalDiagnoseToDelete = row
    },
    confirmAndDelete: function(){
        this.technicalDiagnoseToDelete.user_id = this.$store.getters['auth/id']
        //console.log(this.technicalDiagnoseToDelete)
        this.$store.dispatch("technicalDiagnose/delete", this.technicalDiagnoseToDelete).then((res) => {
            if(res == true) this.deleteTechnicalDiagnoseModal = false
        }).catch((err) => {
            this.error = true
            this.errorContent = err
        }).finally(() => {
            this.result = true
            this.editTechnicalDiagnoseModal = false
        })
    },
    show: function(row){
        this.detailsTechnicalDiagnoseModal = true
        this.detailsTechnicalDiagnose = row
    },

    selectCar(index) {
      this.carSelectionArray = this.carSelectionArray.map(() => false);
      this.carSelectionArray[index] = true;
      this.newTechnicalDiagnose.my_car_id = index
      this.$store.dispatch("technicalDiagnose/getAll", {
          myCarId: this.cars[index].id,
          userId: this.$store.getters['auth/id']
      });
    },
  },

  computed: {
    technical_diagnoses(){
        return this.$store.getters["technicalDiagnose/getTechnicalDiagnoses"];
    },
    technical_diagnose(){
        return this.$store.getters["technicalDiagnose/getTechnicalDiagnose"];
    },

    cars() {
      return this.$store.getters["clientCars/cars"];
    },

    hasCars() {
      return this.cars && this.cars.length > 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.field-label {
     flex-grow: 3!important;
}
</style>